import {Action, ActionReducer} from '@ngrx/store';
import {GlobalStoreActionType} from '../action/global-store-action';

export function clearStateMetaReducer<State extends {}>(reducer: ActionReducer<State>): ActionReducer<State> {
    return function clearStateFn(state: State, action: Action) {
        if (action.type === GlobalStoreActionType.RESET_STORE) {
            state = {} as State;
        }
        return reducer(state, action);
    };
}
