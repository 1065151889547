import {AfterViewInit, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {locale as itLang} from './core/i18n/vocabs/it';
import {locale as enLang} from './core/i18n/vocabs/en';
import {TranslationService} from './core/i18n';
import {ConfigPopup} from 'ngx-cookies-preference';
import {isPlatformBrowser} from '@angular/common';
import {NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {IPressUpStore} from './core/ngrx/stores/interfaces/IPressUpStore';
import {navigationActions} from './core/ngrx/stores/action/navigation-action';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    host: {ngSkipHydration: 'true'}
})
export class AppComponent implements OnInit, AfterViewInit {

    public isBrowser: boolean;
    public configPopupCookies: ConfigPopup;
    public visibleLayoutBox = false;
    private previousUrl: string;
    private currentUrl: string;

    constructor(private translationService: TranslationService,
                private router: Router,
                @Inject(PLATFORM_ID) platformId: object,
                private store: Store<IPressUpStore>) {
        this.isBrowser = isPlatformBrowser(platformId);
        if (this.isBrowser) {
            this.translationService.loadTranslations(itLang, enLang);
        }
        this.currentUrl = this.router.url;
        this.router.events.pipe(
            filter(event => event instanceof NavigationStart)
        ).subscribe((event: NavigationStart) => {
            this.previousUrl = this.currentUrl;
            this.currentUrl = event.url;
            this.store.dispatch(navigationActions.setPreviousUrl({previous: this.previousUrl}));
            this.store.dispatch(navigationActions.setCurrentUrl({current: this.currentUrl}));
        });
    }

    ngOnInit(): void {
        this.configPopupCookiesValue();
    }

    private configPopupCookiesValue(): void {
        this.configPopupCookies = new ConfigPopup();
        this.configPopupCookies.colorPrincipalText = '#080a3c';
        this.configPopupCookies.textTitleBanner = 'Personalizziamo la tua esperienza';
        this.configPopupCookies.textDescriptionBanner = 'Il nostro sito utilizza cookie per le proprie funzionalità e per fornire servizi che migliorano l’esperienza di navigazione. I cookie utilizzati sono:' +
            ' <b>Cookie tecnici:</b> usati per garantire il funzionamento ottimale del sito, <b>Cookies funzionali</b> per comprendere e migliorare l’esperienza di navigazione' +
            ' dell’utente <b>Cookies di marketing</b> per inviarti pubblicità, che potranno essere personalizzate, adatte alle tue preferenze.\n' +
            ' Se vuoi saperne di più o negare il consenso a tutti o ad alcuni cookie clicca "Gestisci impostazioni". Cliccando su “Accetta tutti” acconsenti ' +
            ' all’uso di tutti i cookie. Cliccando su “Accetta essenziali” acconsenti ai cookie tecnici, necessari per la navigazione. Per maggiori informazioni,' +
            ' si invita a leggere la' +
            ' <a href="/privacy" class="clickable" style="color: #003da5 !important;">nostra informativa</a>' +
            ' sulla privacy e la nostra' +
            ' <a href="/cookie-policy" class="clickable" style="color: #003da5 !important;">cookie policy</a>.';
        this.configPopupCookies.textDescriptionBannerCut = 'Il nostro sito utilizza cookie per le proprie funzionalità e per fornire servizi che migliorano l’esperienza di navigazione. I cookie utilizzati sono:';
        this.configPopupCookies.classBtn = 'btn btn-solid p-2 fit-content mx-1';
        this.configPopupCookies.textBtnSetting = 'Gestisci impostazioni';
        this.configPopupCookies.textBtnAcceptEssential = 'Accetta essenzialli';
        this.configPopupCookies.textBtnAcceptAll = 'Accetta tutti';
        this.configPopupCookies.cookieKey = 'cookie-pref';
        this.configPopupCookies.textTitleModal = 'Gestisci impostazioni';
        this.configPopupCookies.textDescriptionModal = 'Per rendere ottimale la navigazione sul sito web, utilizziamo dei cookies per garantire un accesso sicuro, per effettuare delle statistiche' +
            ' per migliorare la fruizione e per poter inviare contenuti personalizzati in base alle tue preferenze. \n' +
            'Cliccando "Accetta selezionati" dichiari di prestare il consenso all’utilizzo dei cookie in base alle preferenze indicate. ' +
            'Cliccando "Accetta tutti" dichiari di prestare il consenso all\'utilizzo di tutti i cookie.\n' +
            'Tetaly utilizza i seguenti <b>Cookie</b>: \n';
        this.configPopupCookies.textTitleTechnical = 'Cookie tecnici';
        this.configPopupCookies.textDescriptionTechnical = 'Necessari per garantire il corretto funzionamento del sito e non possono essere disattivati nei nostri sistemi.';
        this.configPopupCookies.textTitleFunctional = 'Cookie funzionali';
        this.configPopupCookies.textDescriptionFunctional = 'Consentono di analizzare l’uso del sito per migliorare le prestazioni. Inoltre, sono usati per analisi statistiche sulle modalità di navigazione' +
            ' degli utenti, con la finalità di adattare i contenuti secondo le tue necessità rendendo ottimale l’esperienza sul sito.';
        this.configPopupCookies.textTitleMarketing = 'Cookie marketing';
        this.configPopupCookies.textDescriptionMarketing = 'Usati per migliorare l’esperienza di navigazione e per inviare agli utenti inserzioni rilevanti basate sulle preferenze, sulle abitudini ' +
            'd’acquisto e sulla propria navigazione all’interno del sito.';
        this.configPopupCookies.textBtnAcceptSelected = 'Accetta selezionati';
        this.configPopupCookies.textReadMore = 'Continua a leggere';
        this.configPopupCookies.sizeModal = 'lg';
        this.configPopupCookies.sizeTextSpaceModal = '3rem';
    }

    ngAfterViewInit(): void {
        if (this.isBrowser) {
            const element = document.getElementById('popup-container-col-2');
            if (element) {
                element.style.alignSelf = 'center';
            }
        }
    }
}
