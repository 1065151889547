import {Router, UrlTree} from '@angular/router';
import {inject, PLATFORM_ID} from '@angular/core';
import {PagesFacade} from '../../../pages/pages.facade';
import {isPlatformBrowser} from '@angular/common';

export const isUserLoggedGuard = (): UrlTree | boolean => {
    const pagesFacade = inject(PagesFacade);
    const router = inject(Router);
    const platformId = inject(PLATFORM_ID);
    if (isPlatformBrowser(platformId)) {
        if (!pagesFacade.getJwtToken()) {
            return router.createUrlTree(['/home']);
        } else {
            return true;
        }
    } else {
        return true;
    }
};
