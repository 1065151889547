import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {ShopComponent} from './shop/shop.component';
import {PagesComponent} from './pages/pages.component';
import {ElementsComponent} from './elements/elements.component';
import {InvoiceComponent} from './shared/components/invoice/invoice.component';
import {IsLoggedYetGuard} from './modules/auth/guards/is-logged-yet.guard.';
import {isUserLoggedGuard} from "./modules/profile/guards/is-user-logged.guard";

const routes: Routes = [
    {
        path: '',
        redirectTo: '',
        pathMatch: 'full'
    },
    {
        path: '',
        component: PagesComponent,
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
    },
    {
        path: 'auth',
        canActivate: [IsLoggedYetGuard],
        loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    },
    {
        path: 'shop',
        component: ShopComponent,
        loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
    },
    {
        path: 'elements',
        component: ElementsComponent,
        loadChildren: () => import('./elements/elements.module').then(m => m.ElementsModule)
    },
    {
        path: 'profile',
        canActivate: [isUserLoggedGuard],
        loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule)
    },
    {
        path: 'invoice',
        component: InvoiceComponent
    },
    {
        path: '**',
        loadChildren: () => import('./shared/dynamic-loader/dynamic-loader.module').then(m => m.DynamicLoaderModule)
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        useHash: false,
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
